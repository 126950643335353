<template>
	<div id="root">
		<div>
			Filter Centre:

			<el-checkbox-group
				v-model="filters.centres"
				size="medium"
				fill="Orange"
			>
				<el-checkbox-button
					v-for="option in options"
					:label="option"
					:key="option"
					>{{ option }}</el-checkbox-button
				>
			</el-checkbox-group>
		</div>

		<!-- <v-select
			v-model="filters.centres"
			id="centre-select"
			dir="ltr"
			multiple
			label="Centre"
			:options="options"
		/> -->
		<div>Last update: {{ lastUpdate }}</div>
	</div>
</template>

<script>
	import { computed } from "@vue/composition-api";
	import { Centres } from "@configs/twms-info";
	import { hashTextToColor } from "@/utils/ui-helper";
	import { BBadge } from "bootstrap-vue";
  import Colors from "@configs/colors";

	export default {
		components: {
			BBadge,
		},
		props: {
			lastUpdate: {
				type: String,
				required: true,
			},
			filters: {
				type: Object,
				required: true,
			},
		},
		setup(props, ctx) {
			let options = computed(() => {
				return Object.values(Centres).map((centre) => {
					return centre;
				});
			});

			return {
        backgroundColor: Colors.Orange,
				options,
			};
		},
	};
</script>

<style scoped>
	#root {
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
	}

	#centre-select {
		width: 80%;
	}
</style>