<template>
	<div id="root">
		<data-card
			custom-class="m-0"
			:background-color="Colors.Green900"
			:title="titles['D0']"
			:type="DataCardTypes.Number"
			:value="counts['D0']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Green800"
			:title="titles['D1']"
			:type="DataCardTypes.Number"
			:value="counts['D1']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Green700"
			:title="titles['D2']"
			:type="DataCardTypes.Number"
			:value="counts['D2']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Yellow700"
			:title="titles['D3']"
			:type="DataCardTypes.Number"
			:value="counts['D3']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Yellow800"
			:title="titles['D4']"
			:type="DataCardTypes.Number"
			:value="counts['D4']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Yellow900"
			:title="titles['D5']"
			:type="DataCardTypes.Number"
			:value="counts['D5']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Red700"
			:title="titles['D6']"
			:type="DataCardTypes.Number"
			:value="counts['D6']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Red800"
			:title="titles['D7']"
			:type="DataCardTypes.Number"
			:value="counts['D7']"
		/>

		<data-card
			custom-class="m-0"
			:background-color="Colors.Red900"
			:title="titles['D7+']"
			:type="DataCardTypes.Number"
			:value="counts['D7+']"
		/>
	</div>
</template>

<script>
	import { computed, inject, onMounted, onUpdated } from "@vue/composition-api";
	import Colors from "@configs/colors";
	import DataCardTypes from "@common-components/data-card/data-card-types";
	import DataCard from "@common-components/data-card/DataCard.vue";
	import dayjs from "dayjs";

	const formatDate = "YYYY-MM-DD";

	export default {
		components: {
			DataCard,
		},
		setup() {
			const items = inject("summary");
			let counts = computed(() => {
				let counts = {
					D0: 0,
					D1: 0,
					D2: 0,
					D3: 0,
					D4: 0,
					D5: 0,
					D6: 0,
					D7: 0,
					"D7+": 0,
				};
				items.value.forEach((item) => {
					counts["D0"] += item["D0"];
					counts["D1"] += item["D1"];
					counts["D2"] += item["D2"];
					counts["D3"] += item["D3"];
					counts["D4"] += item["D4"];
					counts["D5"] += item["D5"];
					counts["D6"] += item["D6"];
					counts["D7"] += item["D7"];
					counts["D7+"] += item["D7+"];
				});

				return counts;
			});

			let titles = computed(() => {
				return {
					D0: `D0 (${dayjs().format(formatDate)})`,
					D1: `D1 (${dayjs().subtract(1, 'day').format(formatDate)})`,
					D2: `D2 (${dayjs().subtract(2, 'day').format(formatDate)})`,
					D3: `D3 (${dayjs().subtract(3, 'day').format(formatDate)})`,
					D4: `D4 (${dayjs().subtract(4, 'day').format(formatDate)})`,
					D5: `D5 (${dayjs().subtract(5, 'day').format(formatDate)})`,
					D6: `D6 (${dayjs().subtract(6, 'day').format(formatDate)})`,
					D7: `D7 (${dayjs().subtract(7, 'day').format(formatDate)})`,
					"D7+": `D7+ (${dayjs().subtract(7, 'day').format(formatDate)})`,
				};
			});

			return {
				Colors,
				DataCardTypes,

				counts,
				titles,
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}
</style>