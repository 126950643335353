import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const CLOSED_BY_USER = "CLOSED_BY_USER";

export function createWrapperWebSocket($toast, postfix, socketRef, onMessage) {
    const ws = new WebSocket(process.env.VUE_APP_ENDPOINT_WS_URL + postfix);

    $toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
            title: 'Streaming Status',
            icon: "WifiIcon",
            variant: "secondary",
            text: `Connecting...`,
        },
    });

    ws.onopen = function (event) {
        $toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
                title: 'Streaming Status',
                icon: "WifiIcon",
                variant: "success",
                text: `Connected`,
            },
        });
    };

    ws.addEventListener('message', function (event) {
        console.log(postfix + " onMessage: ", event);
        if (onMessage) {
            onMessage(event);
        }
    })

    ws.onclose = function (event) {
        if (event.reason != CLOSED_BY_USER) {
            $toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                    title: 'Streaming Status',
                    icon: "WifiIcon",
                    variant: "danger",
                    text: `Diconnected`,
                },
            });

            setTimeout(function () {
                createWrapperWebSocket($toast, postfix, socketRef);
            }, 5000);
        }

        console.log(postfix, "Socket Closed");
    };

    ws.onerror = function (err) {
        console.log(postfix, "Socket Error", err);
        ws.close();
    };

    ws.closeByUser = function () {
        ws.close(3000, CLOSED_BY_USER);
    }

    socketRef.value = ws;
}