export default {
    drafted: "Drafted",
    hold: "Hold",
    packed: "Packed",
    partial_hand: "Partial Hand",
    pending: "Pending",
    pending_to_handover: "Pending To Handover",
    revert_pending: "Revert Pending",
    shortage: "Shortage",
    stock_allocated: "Stock Allocated",
    wave_assigned: "Wave Assigned"
}