<template>
  <div class="card">
    <div class="card-body">
      <simple-loading
        :is-loading="state.isLoading"
        :is-load-failed="state.loadError"
        @reload="fetchList"
      >
        <b-tabs v-model="activeTab" pills>
          <b-tab v-for="(tab, idx) in tabs" :key="tab.key">
            <template #title>
              <div id="badge-container">
                <span>{{ tab.title }}</span>
                <b-badge :pill="activeTab != idx" variant="primary">
                  {{ tab.count }}
                </b-badge>
              </div>
            </template>

            <outbound-order-summary />
            <outbound-order-tool
              :last-update="state.lastUpdate"
              :filters.sync="state.filters"
            />
            <outbound-order-table />
          </b-tab>
        </b-tabs>
      </simple-loading>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  BTabs,
  BTab,
  BCardText,
  BSkeletonTable,
  BSkeletonImg,
  BBadge,
} from "bootstrap-vue";
import OutboundOrderTypes from "@configs/outbound-order-types";
import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
import apis from "@configs/apis";
import {
  ref,
  reactive,
  computed,
  provide,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from "@vue/composition-api";
import _ from "lodash";
import websocketApis from "@configs/ws-apis";
import webSocketMessageTypes from "@configs/ws-message-types";
import { Centres } from "@configs/twms-info";
import OutboundOrderTable from "./components/OutboundOrderTable.vue";
import { createWrapperWebSocket } from "@/utils/websocket-helper";
import OutboundOrderSummary from "./components/OutboundOrderSummary.vue";
import OutboundOrderTool from "./components/OutboundOrderTool.vue";
import { formatNumber } from "@/utils/ui-helper";
import { getLocalDateTimeString } from '@/utils/date-time-helper';
import { fetcher, FetchMethod } from "@/libs/axios";

const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BSkeletonImg,
    BSkeletonTable,
    OutboundOrderTable,
    SimpleLoading,
    OutboundOrderSummary,
    OutboundOrderTool,
    BBadge,
  },
  setup(props, ctx) {
    const { $toast } = getCurrentInstance().proxy;

    const activeTab = ref(0);
    const socketRef = ref(null);
    const state = reactive({
      list: [],
      filters: {
        centres: Object.values(Centres),
      },
      lastUpdate: "-",
      isLoading: false,
      loadError: false,
    });

    async function fetchList() {
      try {
        state.loadError = false;
        state.isLoading = true;

        const response = await fetcher(apis.listOutboundOrder, FetchMethod.GET);
        state.list = response;
      } catch (error) {
        state.loadError = true;
      } finally {
        state.isLoading = false;
        state.lastUpdate = getLocalDateTimeString();
      }
    }

    function onWSMessage(event) {
      const data = JSON.parse(event.data);
      if (data.messageType == webSocketMessageTypes.OUTBOUND_ORDER_UPDATE) {
        state.list = data.data;
        state.lastUpdate = getLocalDateTimeString();
      }
    }

    onMounted(async () => {
      await fetchList();
      createWrapperWebSocket(
        $toast,
        websocketApis.twmsOutboundOrder,
        socketRef,
        onWSMessage
      );
    });

    onBeforeUnmount(() => {
      if (socketRef.value) {
        socketRef.value.closeByUser();
      }
    });

    const summary = computed(() => {
      const today = dayjs();

      const grouped = _.chain(state.list)
        .filter(
          (row) =>
            row.status == Object.keys(OutboundOrderTypes)[activeTab.value] &&
            state.filters.centres.includes(row.centre)
        )
        .groupBy((row) => `${row.companyName}|${row.centre}`)
        .value();

      const result = [];
      Object.keys(grouped).forEach((key) => {
        const summary = {
          D0: 0,
          D1: 0,
          D2: 0,
          D3: 0,
          D4: 0,
          D5: 0,
          D6: 0,
          D7: 0,
          "D7+": 0, // for more than 7 days
          total: 0,
        };
        grouped[key].forEach((row) => {
          const diff = today.diff(dayjs(row.createDate), "day");
          if (diff > 7) {
            summary["D7+"] += row.count;
          } else {
            summary[`D${diff}`] += row.count;
          }

          summary.total += row.count;
        });

        result.push({
          companyName: key.split("|")[0],
          centre: key.split("|")[1],
          ...summary,
        });
      });
      return result;
    });
    provide("summary", summary);

    const tabs = computed(() => {
      const result = Object.keys(OutboundOrderTypes).map((key) => {
        const count = _.chain(state.list)
          .filter((row) => row.status == key)
          .reduce((sum, item) => (sum += item.count), 0)
          .value();

        return {
          key,
          title: OutboundOrderTypes[key],
          count: formatNumber(count),
        };
      });
      return result;
    });

    return {
      tabs,
      state,
      activeTab,
      fetchList,
    };
  },
};
</script>

<style scoped>
#badge-container {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
</style>
