<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :items="items"
      :fields="fields"
    >
      <template #head(D0)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D1)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D2)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D3)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D4)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D5)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D6)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D7)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(D7+)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #head(total)="data">
        <div id="number-cell-header">
          {{ data.label }}
        </div>
      </template>

      <template #cell(centre)="data">
        <b-badge :style="`background-color: ${hashTextToColor(data.value)}`">
          {{ data.value }}
        </b-badge>
      </template>

      <template #cell(D0)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D1)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D2)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D3)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D4)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D5)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D6)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D7)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(D7+)="data">
        <div id="number-cell">
          {{ data.value > 0 ? formatNumber(data.value) : "-" }}
        </div>
      </template>

      <template #cell(total)="data">
        <div id="number-cell">
          {{ data.value }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  computed, inject, onMounted, onUpdated,
} from '@vue/composition-api'
import { BTable, BBadge } from 'bootstrap-vue'
import { hashTextToColor, formatNumber } from '@/utils/ui-helper'

const formatDate = 'YYYY-MM-DD'

export default {
  components: {
    BTable,
    BBadge,
  },
  setup() {
    onMounted(() => {
      console.debug('Outbound Order Table mounted')
    })

    onUpdated(() => {
      console.debug('Outbound Order Table update')
    })

    const fields = computed(() => [
      { key: 'companyName', label: 'Company Name' },
      { key: 'centre', label: 'Centre' },

      { key: 'D0', label: `D0 (${dayjs().format(formatDate)})` },
      {
        key: 'D1',
        label: `D1 (${dayjs().subtract(1, 'day').format(formatDate)})`,
      },
      {
        key: 'D2',
        label: `D2 (${dayjs().subtract(2, 'day').format(formatDate)})`,
      },
      {
        key: 'D3',
        label: `D3 (${dayjs().subtract(3, 'day').format(formatDate)})`,
      },
      {
        key: 'D4',
        label: `D4 (${dayjs().subtract(4, 'day').format(formatDate)})`,
      },
      {
        key: 'D5',
        label: `D5 (${dayjs().subtract(5, 'day').format(formatDate)})`,
      },
      {
        key: 'D6',
        label: `D6 (${dayjs().subtract(6, 'day').format(formatDate)})`,
      },
      {
        key: 'D7',
        label: `D7 (${dayjs().subtract(7, 'day').format(formatDate)})`,
      },
      {
        key: 'D7+',
        label: `D7+ (>${dayjs().subtract(7, 'day').format(formatDate)})`,
      },
      {
        key: 'total',
        label: 'Total',
      },
    ])

    const items = inject('summary')
    return {
      fields,
      items,
      hashTextToColor,
      formatNumber,
    }
  },
}
</script>

<style scoped>
.b-table-sticky-header {
  /* max-height: 100%; */
}

#number-cell-header {
  display: flex !important;
  justify-content: flex-end !important;
}

#number-cell {
  display: flex;
  justify-content: flex-end;
}
</style>
